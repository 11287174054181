<template>
  <div class="chat-screen-container">
    <div class="chat-screen">
      <!-- Header shows the file name if uploaded -->
      <div class="header bg-light">
        {{ selectedFile ? $t('fileLabel', { fileName: selectedFile.name }) : $t('fileUploadPrompt') }}
      </div>
      <div class="chat-box" ref="chatBox">
        <!-- Loop through messages and align them based on sender -->
        <div
            v-for="(message, index) in chatMessages"
            :key="index"
            class="message-wrapper"
            :class="messageAlignment(message)"
        >
          <span class="message-label" v-if="message.sender === 'user'">{{$t('you')}}</span>
          <span class="message-label" v-else>{{$t('api')}}</span>
          <!-- Use v-html to display the message and replace new lines with <br> -->
          <div class="message-content text-dark" v-html="formatMessage(message.text)"></div>

          <!-- Display download link if the message contains OCR results -->
          <div v-if="message.isOCRMessage" class="ocr-download">
            <button @click="downloadDocx" class="btn-secondary mdi mdi-arrow-down">
              {{ $t('downloadFile') }}
            </button>
          </div>

          <!-- Display alternative questions if they exist -->
          <div v-if="message.alternatives" class="alternatives">
            <div
                v-for="(alternative, idx) in message.alternatives"
                :key="idx"
                class="alternative-item"
                @click="sendAlternativeQuestion(alternative)"
            >
              {{ alternative }}
            </div>
          </div>
        </div>

        <!-- Show file uploading message inside chat board -->
        <div v-if="isUploading" class="message-wrapper user-message">
          <span class="message-label">{{ $t('you') }}</span>
          <div class="message-content">
            {{ $t('uploadingFile') }}
            <div class="loader-animation"></div>
          </div>
        </div>

        <!-- Show loader and message while waiting for response inside chat board -->
        <div v-if="isSendingMessage" class="message-wrapper api-message">
          <span class="message-label">{{ $t('api') }}</span>
          <div class="message-content text-dark">
            {{ $t('waitingForResponse') }}
            <div class="loader-animation"></div>
          </div>
        </div>
      </div>

      <div class="input-box bg-light">
        <!-- PDF Icon for File Upload -->
        <label class="file-upload">
          <input
              type="file"
              @change="onFileChange"
              class="file-input"
              accept="application/pdf"
          />
          <img src="@/assets/pdf-icon.png" alt="Upload PDF" class="pdf-icon" />
        </label>

        <!-- Show file name in the header instead of status -->
        <input
            type="text"
            :placeholder="(selectedFile || isUploading) ? $t('typeMessage') : $t('typeMessage.disabled')"
            v-model="newMessage"
            @keyup.enter="sendMessage"
            :disabled="!selectedFile || isUploading"
            :class="{ 'disabled-input': !selectedFile || isUploading }"
            class="text-input bg-light"
        />
        <button
            @click="sendMessage"
            :disabled="!selectedFile || isUploading"
            :class="{ 'disabled-button': !selectedFile || isUploading }"
            class="text-dark"
        >
          {{ $t('send') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axiosInstance from "@/api/axiosInstance";

export default {
  data() {
    return {
      chatMessages: [],
      newMessage: '',
      selectedFile: null,
      isUploading: false,  // Track if the file is being uploaded
      isSendingMessage: false,  // Track if we are waiting for a response
    };
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim() === '') return;

      // Add the user's message aligned to the right
      this.chatMessages.push({ text: this.newMessage, sender: 'user' });
      this.isSendingMessage = true;

      // Scroll to bottom after message is added
      this.$nextTick(() => {
        this.scrollToBottom();
      });

      // Set a timeout for the long request notification
      const longRequestTimeout = setTimeout(() => {
        this.chatMessages.push({
          text: this.$t('longRequest'),
          sender: 'api',
        });
      }, 7000);

      // Send the message to the server
      axiosInstance.post('/chat', { message: this.newMessage })
          .then((response) => {
            clearTimeout(longRequestTimeout); // Clear the timeout if successful
            const backendResponse = response.data['response'];
            let alternatives =null // = this.extractAlternatives(backendResponse);

            let messageText = backendResponse.split('#ALTERNATIVE')[0].trim();

            this.chatMessages.push({
              text: messageText,
              sender: 'api',
              alternatives: alternatives,
            });

            this.isSendingMessage = false;
            this.$nextTick(() => {
              this.scrollToBottom();
            });
          })
          .catch((error) => {
            clearTimeout(longRequestTimeout); // Clear the timeout if an error occurs
            this.chatMessages.push({
              text: this.$t('sendError'),
              sender: 'api',
            });
            this.isSendingMessage = false;
            console.error('Error sending message:', error);
          });

      this.newMessage = '';
    },
    downloadDocx() {
      // Call the Spring Boot endpoint to download DOCX
      axiosInstance({
        url: '/chat/downloadDocx',
        method: 'GET',
        responseType: 'blob', // Important for handling file downloads
      })
          .then((response) => {
            // Create a blob from the response
            const blob = new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });


            // Create a link element
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = this.selectedFile.name.replace(/\.pdf$/, '.docx'); // Replace .pdf with .docx
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Clean up
            document.body.removeChild(link);
          })
          .catch((error) => {
            console.error('Error downloading DOCX:', error);
          });
    },
    onFileChange(event) {
      this.selectedFile = event.target.files[0];

      if (this.selectedFile) {
        this.uploadFile();
      }
    },
    uploadFile() {
      if (!this.selectedFile) return; // Ensure file is selected

      const formData = new FormData();
      formData.append('file', this.selectedFile);
      this.isUploading = true;

      // Display a "long request" message after 5 seconds if the upload takes too long
      const longRequestTimeout = setTimeout(() => {
        this.chatMessages.push({
          text: this.$t('longRequest'),
          sender: 'api'
        });
        this.$nextTick(() => {
          console.log('UI updated for long request notification.');
        });
      }, 7000);

      // Send file to the backend
      axiosInstance.post('/chat/pdf', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then(response => {
            clearTimeout(longRequestTimeout); // Clear timeout if successful

            // Display success message
            this.chatMessages.push({
              text: this.$t('fileUploaded', { fileName: this.selectedFile.name }),
              sender: 'user'
            });

            // Check for OCR response
            if (response.data.includes("OCR_")) {
              this.chatMessages.push({
                text: this.$t('ocrDetected'),
                sender: 'api',
                isOCRMessage: true
              });
            }

            this.isUploading = false; // Reset upload state
            this.$nextTick(() => {
              this.scrollToBottom();
            });
          })
          .catch(error => {
            clearTimeout(longRequestTimeout); // Clear timeout if error occurs

            // Set default error message
            let backendErrorMessage = this.$t('uploadError');

            // Update error message if response provides more details
            if (error.response && error.response.data) {
              backendErrorMessage = error.response.data;
            }

            this.chatMessages.push({
              text: backendErrorMessage,
              sender: 'api'
            });

            this.isUploading = false; // Reset upload state
            console.error('Error uploading file:', error);
          });
    },
    extractAlternatives(responseText) {
      // Check if response contains #ALTERNATIVE and extract the list
      const alternativeMarker = '#ALTERNATIVE';
      if (responseText.includes(alternativeMarker)) {
        const start = responseText.indexOf('{');
        const end = responseText.indexOf('}');
        if (start !== -1 && end !== -1) {
          const alternativeString = responseText.slice(start + 1, end);
          return alternativeString.split(',').map(alt => alt.trim().replace(/"/g, ''));
        }
      }
      return null;
    },
    sendAlternativeQuestion(question) {
      // Automatically send the clicked alternative question
      this.newMessage = question;
      this.sendMessage();
    },
    messageAlignment(message) {
      return message.sender === 'user' ? 'user-message' : 'api-message';
    },
    formatMessage(text) {
      // Replace newline characters with <br> for HTML display
      return text.replace(/\n/g, '<br>');
    },
    scrollToBottom() {
      const chatBox = this.$refs.chatBox;
      chatBox.scrollTop = chatBox.scrollHeight;
    }
  },
  props: {
    msg: {
      type: String,
      default: 'Welcome to the chat!',
    },
  },
  mounted() {
    this.scrollToBottom(); // Ensure chat is scrolled to bottom when mounted
  },
};
</script>

<style scoped>

@import '@/views/chatscreen/ChatScreen.css';

.notification {
  color: #ff9800;
  margin: 10px 0;
  text-align: center;
}

.error-message {
  color: red;
  margin: 10px 0;
  text-align: center;
}


</style>

<template>
  <div class="bg-light rounded-md shadow px-4 py-5">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center">
          <h4 class="title mb-4">{{ $t('subscribe.news.title') }}</h4>

          <div class="subscribe-form mt-4 pt-2">
            <form>
              <div class="mb-0">
                <input
                    type="email"
                    id="email"
                    name="email"
                    class="border bg-white rounded-pill"
                    required
                    :placeholder="$t('subscribe.news.emailPlaceholder')"
                />
                <button type="submit" class="btn btn-pills btn-dark">
                  {{ $t('subscribe.news.getStarted') }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div><!--end col-->
    </div><!--end row-->
  </div>
</template>


<style lang="scss" scoped></style>

<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h6 class="text-dark">{{ $t('ourPrinciples') }}</h6>
          <h4 class="title mb-4">{{ $t('exploreKeyFeatures') }}</h4>
          <p class="text-muted para-desc mx-auto mb-0">
            {{ $t('discoverDescription') }}
          </p>
        </div>
      </div><!--end col-->
    </div><!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="row">
          <div v-for="item in datas" :key="item.name" class="col-md-6 mt-4 pt-2">
            <div class="d-flex features feature-primary core-feature rounded p-4">
              <div class="icon text-center rounded-circle me-3 mt-2">
                <h3 class="mb-0"><i :class="item.icon"></i></h3>
              </div>
              <div class="flex-1">
                <h5>{{ $t(item.name) }}</h5>
                <p class="text-muted mb-0">{{ $t(item.desc) }}</p>
                <div class="mt-2">
                  <a href="javascript:void(0)" class="link">
                    {{ $t('readMore') }}
                    <i data-feather="arrow-right" class="fea icon-sm"></i>
                  </a>
                </div>
              </div>
            </div>
          </div><!--end col-->
        </div><!--end row-->
      </div><!--end col-->

      <div class="col-12 mt-4 pt-2 text-center">
        <a href="javascript:void(0)" class="text-dark h6 mb-0">
          {{ $t('exploreAllFeatures') }}
          <i data-feather="arrow-right" class="fea icon-sm"></i>
        </a>
      </div><!--end col-->
    </div><!--end row-->
  </div><!--end container-->
</template>

<script setup>
import { ref, onMounted } from 'vue';
import feather from 'feather-icons';

const datas = ref([
  {
    icon: 'uil uil-file-search-alt',
    name: 'pdfSearchQuery',
    desc: 'pdfSearchDesc'
  },
  {
    icon: 'uil uil-legal',
    name: 'legalCaseAnalysis',
    desc: 'legalCaseDesc'
  },
  {
    icon: 'uil uil-medical-drip',
    name: 'medicalTermLookup',
    desc: 'medicalTermDesc'
  },
  {
    icon: 'uil uil-cube',
    name: 'ragBasedResponses',
    desc: 'ragDesc'
  },
]);

onMounted(() => {
  feather.replace();
});
</script>

<style lang="scss" scoped></style>

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedState from 'pinia-plugin-persistedstate';

import App from '@/App.vue';
import router from '@/router';
import { useAuthStore } from '@/stores/auth';
import '@/api/interceptor';
import 'bootstrap/dist/css/bootstrap.css'
import bootsratp from 'bootstrap/dist/js/bootstrap.bundle.js';
import '@/assets/scss/style-dark.scss';
import '@/assets/libs/@iconscout/unicons/css/line.css';
import '@/assets/libs/@mdi/font/css/materialdesignicons.min.css';
import MasonryWall from '@yeger/vue-masonry-wall'

import i18n from './i18n';

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedState);
app.use(pinia);
app.use(router);
app.use(MasonryWall)
app.use(bootsratp)
app.use(router)
app.use(i18n)

const authStore = useAuthStore();
authStore.retrieveSession(); // This should work now

app.mount('#app');

<script setup>
import { ref } from 'vue';
import {useI18n} from "vue-i18n";

// i18n instancef
// i18n instance
const {t} = useI18n();
const datas = ref([
  'home.features.aiDrivenSearch',
  'home.features.seamlessIntegration',
  'home.features.tailoredSolutions'
]);
</script>

<template>
  <div class="row align-items-center">
    <div class="col-lg-6 col-md-5">
      <img src="../assets/images/svg/coworking.svg" :alt="t('home.overview.imageAlt')" />
    </div><!--end col-->

    <div class="col-lg-6 col-md-7">
      <div class="section-title">
        <h1 class="title mb-3">{{ t('home.empower.heading') }}</h1>
        <p class="para-desc text-muted">
          {{ t('home.empower.description') }}
        </p>
        <ul class="list-unstyled text-muted">
          <li v-for="item in datas" :key="item" class="mb-0">
            <span class="text-dark h5 me-2">
              <i class="uil uil-check-circle align-middle"></i>
            </span>{{ t(item) }}
          </li>
        </ul>
        <div class="mt-4">
          <a href="javascript:void(0)" class="btn btn-dark">{{ t('home.empower.learnMore') }}</a>
        </div>
      </div>
    </div><!--end col-->
  </div><!--end row-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-md-5 order-1 order-md-2">
        <img src="../assets/images/freelancer.png" class="img-fluid" :alt="t('home.collaboration.imageAlt')" />
      </div><!--end col-->

      <div class="col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0 order-2 order-md-1">
        <div class="section-title">
          <h1 class="title mb-3">{{ t('home.collaboration.heading') }}</h1>
          <p class="para-desc text-muted">
            {{ t('home.collaboration.description') }}
          </p>
          <ul class="list-unstyled text-muted">
            <li v-for="item in datas" :key="item" class="mb-0">
              <span class="text-dark h5 me-2">
                <i class="uil uil-check-circle align-middle"></i>
              </span>{{ t(item) }}
            </li>
          </ul>
          <div class="mt-4">
            <a href="javascript:void(0)" class="btn btn-dark">{{ t('home.collaboration.learnMore') }}</a>
          </div>
        </div>
      </div><!--end col-->
    </div><!--end row-->
  </div><!--end container-->
</template>


<style lang="scss" scoped></style>

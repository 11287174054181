<template>
  <div class="container mt-5">
    <div class="card">
      <div class="card-header bg-primary text-white">
        <h4 class="mb-0">Chat with AI Assistant</h4>
      </div>
      <div class="card-body">
        <div class="chat-window mb-3" ref="chatWindow">
          <div v-for="(message, index) in messages" :key="index" class="mb-2">
            <div v-if="message.role === 'user'" class="alert alert-primary">
              <strong>You:</strong> {{ message.content }}
            </div>
            <div v-else class="alert alert-secondary">
              <strong>AI:</strong> {{ message.content }}
            </div>
          </div>
        </div>

        <form @submit.prevent="sendMessage">
          <div class="mb-3">
            <textarea
                v-model="userMessage"
                class="form-control"
                rows="3"
                placeholder="Type your message..."
                required
            ></textarea>
          </div>
          <div class="d-flex justify-content-end">
            <button class="btn btn-success" type="submit" :disabled="isLoading">
              <span v-if="isLoading" class="spinner-border spinner-border-sm"></span>
              <span v-else>Send</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/api/axiosInstance";


export default {
  name: 'ChatComponent',
  data() {
    return {
      messages: [],
      userMessage: '',
      assistantId: 1, // Adjust this ID based on the existing AI Assistant ID
      isLoading: false,
    };
  },
  methods: {
    async sendMessage() {
      if (!this.userMessage.trim()) return;

      // Add the user's message to the chat window
      this.messages.push({ role: 'user', content: this.userMessage });

      // Scroll to the bottom of the chat window
      this.scrollToBottom();

      // Prepare the request payload
      const chatRequest = {
        username: this.assistantId,
        question: this.userMessage,
      };

      try {
        this.isLoading = true;

        // Make the API call to the backend
        const response = await axiosInstance.post('/chat/assistant', chatRequest);

        // Add the AI's response to the chat window
        this.messages.push({ role: 'ai', content: response.data.response });
      } catch (error) {
        console.error('Error during chat:', error);
        this.messages.push({ role: 'ai', content: 'Error: Unable to get a response from AI.' });
      } finally {
        // Reset user message input and loading state
        this.userMessage = '';
        this.isLoading = false;

        // Scroll to the bottom of the chat window
        this.scrollToBottom();
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatWindow = this.$refs.chatWindow;
        chatWindow.scrollTop = chatWindow.scrollHeight;
      });
    },
  },
};
</script>

<style scoped>
.chat-window {
  height: 400px;
  overflow-y: auto;
  background-color: #f8f9fa;
  padding: 10px;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}
</style>

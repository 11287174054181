<template>
  <div class="doc_chat-screen-container">
    <div v-if="!filesUploaded" class="doc_upload-screen">
      <div class="doc_header">Document Upload</div>

      <!-- File upload section -->
      <div class="doc_upload-box">
        <h3>Upload Documents</h3>
        <form @submit.prevent="uploadDocuments" enctype="multipart/form-data" class="doc_input-box">
          <label for="file-upload" class="doc_file-upload">Choose PDF Files</label>
          <input type="file" id="file-upload" multiple @change="handleFileUpload" accept=".pdf" class="doc_file-input" />

          <!-- Display selected files -->
          <div v-if="files.length > 0" class="doc_file-list">
            <h4>Selected Files:</h4>
            <ul>
              <li v-for="file in files" :key="file.name">
                {{ file.name }} ({{ (file.size / 1024).toFixed(2) }} KB)
              </li>
            </ul>
          </div>

          <button type="submit" :class="{'doc_disabled-button': !files.length}" :disabled="!files.length">Upload</button>
        </form>
      </div>
    </div>

    <!-- Chat screen: shown only after files are uploaded -->
    <div v-else class="doc_chat-screen">
      <div class="doc_header">Search and View Paragraphs</div>

      <div class="doc_chat-box">
        <!-- Uploaded Paragraphs -->
        <div v-if="uploadedParagraphs.length > 0">
          <h3>Uploaded Paragraphs</h3>
          <ul class="doc_message-wrapper">
            <li v-for="paragraph in uploadedParagraphs" :key="paragraph.id" class="doc_api-message">
              <span class="doc_message-label">{{ paragraph.fileName }}</span>
              <div class="doc_message-content">{{ paragraph.paragraphText }}</div>
            </li>
          </ul>
        </div>

        <!-- Search input -->


        <!-- Search results -->
        <div v-if="searchResults.length > 0">
          <h3>Search Results</h3>
          <ul class="doc_message-wrapper">
            <li v-for="result in searchResults" :key="result.id" class="doc_api-message">
              <span class="doc_message-label">{{ result.fileName }}</span>
              <div class="doc_message-content">{{ result.paragraphText }}</div>
            </li>
          </ul>
        </div>

        <h3>Search Similar Paragraphs</h3>
        <form @submit.prevent="searchSimilarParagraphs" class="doc_input-box">
          <input v-model="searchQuery" type="text" placeholder="Enter search text" class="doc_text-input" :class="{'doc_disabled-input': !uploadedParagraphs.length}" :disabled="!uploadedParagraphs.length" />
          <button type="submit" :class="{'doc_disabled-button': !searchQuery}" :disabled="!searchQuery">Search</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/api/axiosInstance";

export default {
  data() {
    return {
      files: [],
      filesUploaded: false, // Track if files are uploaded
      uploadedParagraphs: [],
      searchQuery: '',
      searchResults: [],
    };
  },
  methods: {
    handleFileUpload(event) {
      this.files = Array.from(event.target.files);
    },
    async uploadDocuments() {
      const formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        formData.append('files', this.files[i]);
      }

      try {
        const response = await axiosInstance.post('/documents/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.status === 200) {
          this.uploadedParagraphs = response.data;
          this.filesUploaded = true; // Show chat screen after successful upload
        } else {
          console.error('Failed to upload files');
        }
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    },
    async searchSimilarParagraphs() {
      try {
        const response = await axiosInstance.post('/documents/search', this.searchQuery, {
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (response.status === 200) {
          this.searchResults = response.data;
        } else {
          console.error('Failed to search for paragraphs');
        }
      } catch (error) {
        console.error('Error searching for paragraphs:', error);
      }
    },
  },
};
</script>

<style scoped>
@import '@/views/documents/DocumentUpload.css';
</style>

<template>
  <div class="container mt-4">
    <h2 class="mb-4">Prompts</h2>
    <div class="row align-items-center mb-2 border-bottom pb-2">
      <div class="col-1">Priority</div>
      <div class="col-auto">Disabled</div>
      <div class="col">Prompt</div>
      <div class="col-auto">Actions</div>
    </div>
    <div v-for="(prompt, index) in prompts" :key="index"
         class="row align-items-center mb-2 border-bottom pb-2">
      <div class="col-1">
        <input v-model.number="prompt.priority" type="number"
               class="form-control" placeholder="Priority"/>
      </div>
      <div class="col-auto text-center">
        <input v-model="prompt.disabled" type="checkbox"
               class="form-check-input"/>
      </div>
      <div class="col">
        <input v-model="prompt.prompt" type="text"
               class="form-control text-xl-start" style="font-size: 15px"
               placeholder="Prompt"/>
      </div>
      <div class="col-auto">
        <button @click="deletePrompt(index)" class="btn btn-danger btn-sm">
          Delete
        </button>
      </div>
    </div>
    <button @click="addPrompt" class="btn btn-success mt-3">Add Prompt</button>
    <button @click="updatePrompts" class="btn btn-dark mt-3 ms-2">Save Changes
    </button>
  </div>
</template>

<script>
import axiosInstance from "@/api/axiosInstance";

export default {
  data() {
    return {
      prompts: [],
    };
  },
  methods: {
    fetchPrompts() {
      axiosInstance.get('/prompts')
          .then(response => {
            this.prompts = response.data.promptLines;
          })
          .catch(error => {
            console.error('Error fetching prompts:', error);
          });
    },
    updatePrompts() {
      axiosInstance.put('/prompts', {promptLines: this.prompts})
          .then(() => {
            alert('Prompts updated successfully');
            this.prompts = [];
            this.fetchPrompts();
          })
          .catch(error => {
            console.error('Error updating prompts:', error);
          });
    },
    addPrompt() {
      this.prompts.push({prompt: '', priority: 0, disabled: false});
    },
    deletePrompt(index) {
      this.prompts.splice(index, 1);
    }
  },
  mounted() {
    this.fetchPrompts();
  },
};
</script>

<style scoped>
/* Add any custom styles if needed */
</style>

<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="section-title text-center mb-4 pb-2">
        <h6 class="text-dark">{{ $t('pricing.sectionTitle') }}</h6>
        <h4 class="title mb-4">{{ $t('pricing.flexiblePlans') }}</h4>
        <p class="text-muted para-desc mx-auto mb-0">
          {{ $t('pricing.choosePlan') }}
        </p>
      </div>
    </div><!--end col-->
  </div><!--end row-->

  <div class="row justify-content-center">
    <div class="col-lg-9">
      <div v-for="(plan, index) in plans" :key="index">
        <div class="mt-4 pt-2">
          <div class="rounded shadow p-4">
            <div class="row align-items-center">
              <div class="col-md-3">
                <h5>{{ $t(plan.name) }}</h5>
                <div class="d-flex text-dark">
                  <span class="h6 mb-0 mt-2">$</span>
                  <span class="price h2 mb-0">{{ plan.price }}</span>
                  <span class="h6 align-self-end mb-1"> /{{ $t('pricing.perMonth') }}</span>
                </div>
              </div><!--end col-->

              <div class="col-md-6 mt-4 mt-sm-0">
                <p class="text-muted mb-0">{{ $t(plan.description) }}</p>
              </div><!--end col-->

              <div class="col-md-3 mt-4 mt-sm-0 text-center text-md-end">
                <div class="d-grid">
                  <a href="javascript:void(0)" :class="`btn ${plan.buttonClass}`">
                    {{ $t(plan.buttonText) }}
                  </a>
                </div>
              </div><!--end col-->
            </div><!--end row-->
          </div>
        </div>
      </div>
    </div><!--end col-->
  </div><!--end row-->
</template>

<script setup>
import { ref } from 'vue';

const plans = ref([
  {
    name: 'pricing.plan.basic.name',
    price: 0,
    description: 'pricing.plan.basic.description',
    buttonClass: 'btn-outline-dark',
    buttonText: 'pricing.plan.basic.buttonText'
  },
  {
    name: 'pricing.plan.professional.name',
    price: 29,
    description: 'pricing.plan.professional.description',
    buttonClass: 'btn-soft-dark',
    buttonText: 'pricing.plan.professional.buttonText'
  },
  {
    name: 'pricing.plan.enterprise.name',
    price: 79,
    description: 'pricing.plan.enterprise.description',
    buttonClass: 'btn-dark',
    buttonText: 'pricing.plan.enterprise.buttonText'
  }
]);
</script>

<style lang="scss" scoped></style>

import Register from "@/views/RegisterScreen.vue";
import Login from "@/views/LoginScreen.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import Landing from '@/pages/index/index.vue';
import ForgottenPasswordView from "@/views/ForgottenPasswordView.vue";
import AdminPage from "@/views/AdminPage.vue";
import UserPage from "@/views/UserPage.vue";
import ResetPasswordPage from "@/views/ResetPasswordPage.vue";
import ChatScreen from "@/views/chatscreen/ChatScreen.vue";
import Prompts from "@/views/prompts/PromptsView.vue";
import AiChat from "@/views/asistant/OpenAiAssistant.vue";

import DocumentUpload from "@/views/documents/DocumentUpload.vue";

import {
    adminGuard,
    authenticatedGuard,
    redirectIfAuthenticated,
    userGuard
} from "@/router/guards";


const routes =
    [
        {
            path: "/",
            name: "landing",
            component: Landing,
        },
        {
            path: "/signup",
            name: "signup",
            component: Register,
            beforeEnter: redirectIfAuthenticated,
        },
        {
            path: "/login",
            name: "login",
            component: Login,
            beforeEnter: redirectIfAuthenticated,
        },
        {
            path: "/forgotten-password",
            name: "forgotten-password",
            component: ForgottenPasswordView,
            beforeEnter: redirectIfAuthenticated,
        },
        {
            path: "/reset-password",
            name: "reset-password",
            component: ResetPasswordPage,
            beforeEnter: redirectIfAuthenticated,
        },
        {
            path: "/chatScreen",
            name: "chatScreen",
            component: ChatScreen,
            beforeEnter: authenticatedGuard,
        },
        {
            path: "/prompts",
            name: "prompts",
            component: Prompts,
            beforeEnter: authenticatedGuard,
        },
        {
            path: "/aiChat",
            name: "aiChat",
            component: AiChat,
            beforeEnter: authenticatedGuard,
        },
        {
            path: "/documents",
            name: "documents",
            component: DocumentUpload,
            beforeEnter: authenticatedGuard,
        },
        {
            path: "/admin",
            name: "admin",
            component: AdminPage,
            beforeEnter: adminGuard,
        },
        {
            path: "/user",
            name: "userPage",
            component: UserPage,
            beforeEnter: userGuard,
        },
        {
            path: "/404",
            name: "not-found",
            component: NotFoundView,
        },
        // catch all 404
        {
            path: "/:pathMatch(.*)*",
            redirect: "/404",
        },
    ];

export default routes;

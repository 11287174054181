// src/api/interceptor.js

import axiosInstance from "@/api/axiosInstance";
import router from "@/router";
import { useAuthStore } from "@/stores/auth";

// Set the access token from localStorage if available
const accessToken = localStorage.getItem("access_token");
if (accessToken) {
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
}

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (
          error.response &&
          error.response.status === 401 &&
          error.response.data === "Access token expired"
      ) {
        console.log("Access token expired, trying to refresh it");
        try {
          const response = await axiosInstance.post(
              "/auth/refresh-token",
              null,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("refresh_token")}`,
                },
              }
          );

          // Update the access token in local storage
          localStorage.setItem("access_token", response.data.access_token);

          // Retry the original request with the new access token
          const { config } = error;
          config.headers.Authorization = `Bearer ${response.data.access_token}`;
          return axiosInstance.request(config);
        } catch (refreshError) {
          if (refreshError.response && refreshError.response.status === 403) {
            console.log("Refresh token expired, logging out the user");
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");

            const authStore = useAuthStore();
            authStore.logout();

            await router.push({
              path: "/login",
              query: { sessionExpired: true },
            });
          }
        }
      }
      return Promise.reject(error);
    }
);

<script setup>
import axiosInstance from "@/api/axiosInstance";
import router from "@/router";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

// i18n instance
const { t } = useI18n();

const registerRequest = ref({
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  role: "ROLE_USER",
  confirmPassword: "",
});

const isLoading = ref(false);
const errorsArray = ref([]);
const errorMessage = ref("");

const validatePassword = () => {
  const hasMinLength = registerRequest.value.password.length >= 8;
  const hasDigit = /\d/.test(registerRequest.value.password);
  const hasLowerCase = /[a-z]/.test(registerRequest.value.password);
  const hasUpperCase = /[A-Z]/.test(registerRequest.value.password);
  const passwordMatch = registerRequest.value.password === registerRequest.value.confirmPassword;

  return hasMinLength && hasDigit && hasLowerCase && hasUpperCase && passwordMatch;
};

const submit = async () => {
  try {
    clearErrors();
    isLoading.value = true;
    const response = await axiosInstance.post("auth/register", registerRequest.value);

    if (response.status === 201) await router.push("/login");
  } catch (error) {
    isLoading.value = false;
    if (error.response) {
      if (error.response.status === 422)
        errorsArray.value = error.response.data.message.slice(1, -1).split(", ");
      else {
        showErrorMessage(error.response.data);
      }
    } else if (error.request) {
      showErrorMessage(t("register.connectionError"));
    } else {
      showErrorMessage(t("register.generalError"));
    }
  }
};

const showErrorMessage = (message) => {
  errorMessage.value = message;
};

const clearErrors = () => {
  errorsArray.value = [];
  errorMessage.value = "";
};
</script>

<template>
  <section class="py-4 py-md-5 my-5">
    <div class="container py-md-5">
      <div class="row">
        <div class="col-md-6 text-center">
          <!-- <img class="img-fluid w-100" src="@/assets/img/illustrations/login.svg" alt="login-img" /> -->
        </div>
        <div class="col-md-5 col-xl-4 text-center text-md-start">
          <h2 class="display-6 fw-bold mb-5">
            <span class="underline pb-1">
              <strong>{{ $t('register.title') }}</strong>
            </span>
          </h2>
          <form @submit.prevent="submit">
            <div class="mb-3">
              <input
                  v-model="registerRequest.firstName"
                  class="shadow-sm form-control"
                  required
                  type="text"
                  name="first-name"
                  :placeholder="$t('register.firstNamePlaceholder')"
              />
            </div>
            <div class="mb-3">
              <input
                  v-model="registerRequest.lastName"
                  class="shadow-sm form-control"
                  required
                  type="text"
                  name="last-name"
                  :placeholder="$t('register.lastNamePlaceholder')"
              />
            </div>
            <div class="mb-3">
              <input
                  v-model="registerRequest.email"
                  class="shadow-sm form-control"
                  required
                  type="email"
                  name="email"
                  :placeholder="$t('register.emailPlaceholder')"
              />
            </div>
            <div class="mb-3">
              <input
                  v-model="registerRequest.password"
                  class="shadow-sm form-control"
                  required
                  type="password"
                  name="password"
                  :placeholder="$t('register.passwordPlaceholder')"
              />
            </div>
            <div class="mb-3">
              <input
                  v-model="registerRequest.confirmPassword"
                  class="shadow-sm form-control"
                  required
                  type="password"
                  name="password_repeat"
                  :placeholder="$t('register.confirmPasswordPlaceholder')"
              />
            </div>

            <!-- Password requirements -->
            <div class="mb-3">
              <ul v-if="registerRequest.password.length > 0" class="password-requirements">
                <li v-if="registerRequest.password.length < 8" class="text-danger">
                  &#x2718; {{ $t('register.requirements.minLength') }}
                </li>
                <li v-if="!/\d/.test(registerRequest.password)" class="text-danger">
                  &#x2718; {{ $t('register.requirements.digit') }}
                </li>
                <li v-if="!/[a-z]/.test(registerRequest.password)" class="text-danger">
                  &#x2718; {{ $t('register.requirements.lowercase') }}
                </li>
                <li v-if="!/[A-Z]/.test(registerRequest.password)" class="text-danger">
                  &#x2718; {{ $t('register.requirements.uppercase') }}
                </li>
                <li v-if="registerRequest.password !== registerRequest.confirmPassword" class="text-danger">
                  &#x2718; {{ $t('register.requirements.match') }}
                </li>
              </ul>
            </div>

            <div class="mb-5">
              <button
                  class="btn btn-dark shadow"
                  type="submit"
                  :disabled="!validatePassword() || isLoading"
              >
                <span v-if="isLoading" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                {{ isLoading ? $t('register.creatingAccount') : $t('register.createAccount') }}
              </button>
            </div>

            <div v-if="errorMessage" class="alert alert-danger">
              {{ errorMessage }}
            </div>

            <div v-if="errorsArray.length" class="alert alert-danger">
              <ul>
                <li v-for="(error, index) in errorsArray" :key="index">
                  {{ error }}
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.password-requirements {
  list-style-type: none;
  padding-left: 0;
}
</style>

import axios from "axios";

// set the base URL from the .env file

// create an axios instance
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL+"/api/v1/",
});



// set the Authorization header for every request
const token = localStorage.getItem("access_token");
if (token != null) {
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export default axiosInstance;

<template>
  <!-- Hero Start -->
  <section
      class="bg-half-130 d-table w-100 overflow-hidden"
      :style="{ 'background-image': 'url(' + image + ')', backgroundPosition: 'top' }"
  >
    <div class="container">
      <div class="row mt-5 align-items-center pt-5">
        <div class="col-lg-6 col-md-7">
          <div class="heading-title">
            <h1 class="heading fw-bold mb-3">
              {{ $t('heroTitle') }}
            </h1>
            <p class="para-desc text-muted">
              {{ $t('heroDescription') }}
            </p>
            <div class="watch-video mt-4 pt-2">
              <a href="javascript:void(0)" class="btn btn-dark mb-2">
                {{ $t('getStarted') }}
              </a>
              <a
                  @click="toggle"
                  data-type="youtube"
                  :data-id="youtubeId"
                  class="lightbox video-play-icon watch text-dark mb-2 ms-2"
              >
                <i
                    class="mdi mdi-play play-icon-circle text-center d-inline-block me-2 rounded-circle text-white title-dark position-relative play play-iconbar"
                ></i>
                {{ $t('watchDemo') }}
              </a>
            </div>
          </div>
        </div>
        <!--end col-->

        <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
          <div class="bg-saas-shape position-relative">
            <img src="@/assets/images/blog/4.jpg" class="mx-auto d-block" alt="Aisistan Screenshot" />
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!-- Hero End -->

  <!-- Modal Video -->
  <div
      v-if="!isActive"
      class="modal-video"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
      :aria-label="$t('modalVideoLabel')"
  >
    <div class="modal-video-body">
      <div class="modal-video-inner">
        <div class="modal-video-movie-wrap">
          <iframe
              width="460"
              height="230"
              :src="`https://www.youtube.com/embed/${youtubeId}?feature=oembed`"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              tabindex="-1"
          ></iframe>
          <a @click="toggle" class="mdi mdi-close m-3 h4 position-class title-icon"> </a>
        </div>
      </div>
    </div>
  </div>

  <!-- Sections -->
  <section class="section">
    <principle />
    <div class="container mt-100 mt-60">
      <services />
    </div>
    <div class="container mt-100 mt-60">
      <subscribe />
    </div>
    <div class="container mt-100 mt-60">
      <pricing />
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import image from '@/assets/images/bg/bg-index.png';
import principle from '@/components/our-principles.vue';
import services from '@/components/principel-services.vue';
import subscribe from '@/components/subscribe.vue';
import pricing from '@/components/pricing.vue';

const isActive = ref(true);
const youtubeId = ref('yba7hPeTSjk');

const toggle = () => {
  isActive.value = !isActive.value;
};
</script>

<style lang="scss" scoped></style>
